import React, { useState } from 'react'

import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'
import NmotTranslationText from 'src/views/nmot/shared/NmotTranslationText'
import AddDestDialogContext from './AddDestDialogContext.js'
import AddDestDialogForm from './AddDestDialogForm.js'
import Paper from '@material-ui/core/Paper'
import { components } from 'cng-web-lib'

const { CngDialog } = components

function AddDestDialog({
  isDialogOpen,
  closeDialog,
  showNotification,
  cngTableRef
}) {
  const [isSubmitting, setSubmitting] = useState(false)
  const translatedTextsObject = NmotTranslationText()

  return (
    <AddDestDialogContext.Provider
      value={{
        closeDialog,
        showNotification,
        form: {
          isSubmitting,
          setSubmitting
        },
        cngTableRef
      }}
    >
      <CngDialog
        customDialogContent={
          <Paper>
            <div style={{ overflow: 'hidden' }}>
              <AddDestDialogForm />
            </div>
          </Paper>
        }
        dialogTitle={
          <>
            {translatedTextsObject.addDestinationTitle}
            <CloseDialogIconButton
              onClick={closeDialog}
              disabled={isSubmitting}
            />
          </>
        }
        open={isDialogOpen}
        fullWidth
        maxWidth='sm'
      />
    </AddDestDialogContext.Provider>
  )
}

export default AddDestDialog
