import { components, useServices } from 'cng-web-lib'
import React, { useContext } from 'react'

import CancelButton from 'src/components/button/CancelButton.js'
import NmotTranslationText from 'src/views/nmot/shared/NmotTranslationText'
import AllRowsDialogContext from './AllRowsDialogContext.js'
import NMoTApiUrls from 'src/apiUrls/NMoTApiUrls.js'
import { Box, Grid, CircularProgress } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

const {
  table: { useDefaultNotification },
  button: { CngPrimaryButton }
} = components

function AllRowsDialogForm(props) {
  const translatedTextsObject = NmotTranslationText()
  const { createRecord, deleteRecord, securedSendRequest } = useServices()
  const dialogContext = useContext(AllRowsDialogContext)
  const {
    closeDialog,
    showNotification,
    form: {
      isDeleting,
      setDeleting,
      isArchiving,
      setArchiving,
      isDownloading,
      setDownloading
    },
    cngTableRef
  } = dialogContext
  const { success: showSuccessNotification, error: showErrorNotification } =
    useDefaultNotification(showNotification)

  function onArchiveAll() {
    function onSuccess() {
      setArchiving(false)
      closeDialog()
      if (cngTableRef.current && cngTableRef.current.performRefresh) {
        cngTableRef.current.performRefresh()
      }
      showSuccessNotification(translatedTextsObject.recordsArchiveSuccess)
    }

    function onError(error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.errorMessages &&
        error.response.data.errorMessages.length > 0
      ) {
        showErrorNotification(error.response.data.errorMessages)
      } else {
        showErrorNotification(error.message)
      }
      console.log('nmot-archive-all error', error.message)
      setArchiving(false)
    }

    setArchiving(true)

    createRecord.execute(
      NMoTApiUrls.NMOT_DASHBOARD_ALL_ARCHIVE,
      {},
      onSuccess,
      onError
    )
  }

  function onDeleteAll() {
    function onSuccess() {
      setDeleting(false)
      closeDialog()
      if (cngTableRef.current && cngTableRef.current.performRefresh) {
        cngTableRef.current.performRefresh()
      }
      showSuccessNotification(translatedTextsObject.recordsDeleteSuccess)
    }

    function onError(error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.errorMessages &&
        error.response.data.errorMessages.length > 0
      ) {
        showErrorNotification(error.response.data.errorMessages)
      } else {
        showErrorNotification(error.message)
      }
      console.log('nmot-delete-all error', error.message)
      setDeleting(false)
    }

    setDeleting(true)

    deleteRecord.execute(
      NMoTApiUrls.NMOT_DASHBOARD_ALL_DELETE,
      {},
      onSuccess,
      onError
    )
  }

  function onDownloadAll() {
    function onSuccess(data) {
      let byteArrays = base64toBlob(data.fileContentData);
      let blob = new Blob(byteArrays, {
        type: 'application/vnd.ms-excel'
      })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = "Dashboard-Export.xlsx"
      link.click()

      setDownloading(false)
      closeDialog()
      showSuccessNotification(translatedTextsObject.recordsDownloadSuccess)
    }

    function onError(error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.errorMessages &&
        error.response.data.errorMessages.length > 0
      ) {
        showErrorNotification(error.response.data.errorMessages)
      } else {
        showErrorNotification(error.message)
      }
      console.log('nmot-download-all error', error.message)
      setDownloading(false)
    }

    setDownloading(true)

    createRecord.execute(
      NMoTApiUrls.NMOT_DASHBOARD_DOWNLOAD,
      {},
      onSuccess,
      onError
    )
  }

  function base64toBlob(base64Data) {
    let sliceSize = 1024
    let byteCharacters = atob(base64Data)
    let bytesLength = byteCharacters.length
    let slicesCount = Math.ceil(bytesLength / sliceSize)
    let byteArrays = new Array(slicesCount)

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      let begin = sliceIndex * sliceSize
      let end = Math.min(begin + sliceSize, bytesLength)

      let bytes = new Array(end - begin)
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0)
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes)
    }
    return byteArrays
  }

  return (
    <Box m={1.5}>
      <Grid container spacing={2} justify='center' alignItems='center'>
        <Grid item xs={1} sm={1}>
          <Box py={1}>
            <FontAwesomeIcon
              icon={faExclamationCircle}
              color='#1769aa'
              style={{ fontSize: 30 }}
            />
          </Box>
        </Grid>
        <Grid item xs={11} sm={11}>
          <Box mb={1}>
            <div>
              <b> Archive All or Delete All: </b>
              All records will be cleared from the Dashboard. <br />
              Are you sure to proceed? <br />
              <b> Download All: </b>
              All records will be downloaded as excel file.
            </div>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2} justify='center'>
        <Box my={1} mr={1}>
          <CngPrimaryButton
            disabled={isDownloading || isDeleting || isArchiving}
            onClick={() => {
              onArchiveAll()
            }}
          >
            {translatedTextsObject.archiveAll}
            {isArchiving && (
              <Box mx={0.5}>
                <CircularProgress color='primary' size={10} />
              </Box>
            )}
          </CngPrimaryButton>
        </Box>
        <Box my={1} mr={1}>
          <CngPrimaryButton
            disabled={isDownloading || isDeleting || isArchiving}
            onClick={() => {
              onDeleteAll()
            }}
          >
            {translatedTextsObject.deleteAll}

            {isDeleting && (
              <Box mx={0.5}>
                <CircularProgress color='primary' size={10} />
              </Box>
            )}
          </CngPrimaryButton>
        </Box>
        <Box my={1} mr={1}>
          <CngPrimaryButton
            disabled={isDownloading || isDeleting || isArchiving}
            onClick={() => {
              onDownloadAll()
            }}
          >
            {translatedTextsObject.downloadAll}
            {isDownloading && (
              <Box mx={0.5}>
                <CircularProgress color='primary' size={10} />
              </Box>
            )}
          </CngPrimaryButton>
        </Box>
        <Box my={1} mr={1}>
          <CancelButton
            disabled={isDownloading || isDeleting || isArchiving}
            onClick={closeDialog}
          />
        </Box>
      </Grid>
    </Box>
  )
}

export default AllRowsDialogForm
