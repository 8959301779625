import React, { useState } from 'react'

import CloseDialogIconButton from 'src/components/iconbutton/CloseDialogIconButton.js'
import NmotTranslationText from 'src/views/nmot/shared/NmotTranslationText'
import SelectedRowsDialogContext from './SelectedRowsDialogContext.js'
import SelectedRowsDialogForm from './SelectedRowsDialogForm.js'
import Paper from '@material-ui/core/Paper'
import { components } from 'cng-web-lib'

const { CngDialog } = components

function SelectedRowsDialog({
  isDialogOpen,
  closeDialog,
  showNotification,
  cngTableRef,
  rowData,
  setSelectedRowData
}) {
  const translatedTextsObject = NmotTranslationText()
  const [isDeleting, setDeleting] = useState(false)
  const [isArchiving, setArchiving] = useState(false)

  return (
    <SelectedRowsDialogContext.Provider
      value={{
        closeDialog,
        showNotification,
        rowData,
        setSelectedRowData,
        form: {
          isDeleting,
          setDeleting,
          isArchiving,
          setArchiving
        },
        cngTableRef
      }}
    >
      <CngDialog
        customDialogContent={
          <Paper>
            <div style={{ overflow: 'hidden' }}>
              <SelectedRowsDialogForm />
            </div>
          </Paper>
        }
        dialogTitle={
          <>
            {rowData.length > 0
              ? translatedTextsObject.confirm
              : translatedTextsObject.warning}
            <CloseDialogIconButton
              onClick={closeDialog}
              disabled={isDeleting || isArchiving}
            />
          </>
        }
        open={isDialogOpen}
        fullWidth
        maxWidth='sm'
      />
    </SelectedRowsDialogContext.Provider>
  )
}

export default SelectedRowsDialog
