import NmotValidationKeys from 'src/constants/locale/key/NmotValidation'
import Namespace from 'src/constants/locale/Namespace'
import { useTranslation, constants } from 'cng-web-lib'


const {
    locale: {
        key: { CommonValidationMessageKeys }
    }
} = constants

const ValidationMessageTranslationText = () => {
    const { translate } = useTranslation([
        Namespace.NMOT_VALIDATION,
        Namespace.COMMON_VALIDATION_MSG,
    ])

    let required = translate(
        Namespace.COMMON_VALIDATION_MSG,
        CommonValidationMessageKeys.REQUIRED
    )

    let wordLimitExceedMsg = translate(
        Namespace.NMOT_VALIDATION,
        NmotValidationKeys.WORD_LIMIT_EXCEED_MSG
    )

    let wordLimitMinMsg = translate(
        Namespace.NMOT_VALIDATION,
        NmotValidationKeys.WORD_LIMIT_MIN_MSG
    )

    let invalidStringWspace = translate(
        Namespace.NMOT_VALIDATION,
        NmotValidationKeys.INVALID_STRING_W_SPACE
    )

    let invalidEmojiMsg = translate(
        Namespace.NMOT_VALIDATION,
        NmotValidationKeys.INVALID_EMOJI_MSG
    )

    let invalidStringWSpecialChar = translate(
        Namespace.NMOT_VALIDATION,
        NmotValidationKeys.INVALID_STRING_W_SPECIAL_CHAR
    )

    let invalidContNo = translate(
        Namespace.NMOT_VALIDATION,
        NmotValidationKeys.INVALID_CONT_NO
    )

    return {
        required,
        wordLimitExceedMsg,
        wordLimitMinMsg,
        invalidStringWspace,
        invalidEmojiMsg,
        invalidStringWSpecialChar,
        invalidContNo
    }
}

export default ValidationMessageTranslationText