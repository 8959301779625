import { Box, Paper, Typography, makeStyles } from '@material-ui/core'
import { Plus, Trash, Check, Edit2 } from 'react-feather'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { components, useServices, constants } from 'cng-web-lib'
import moment from 'moment'

import NmotTranslationText from 'src/views/nmot/shared/NmotTranslationText'
import AddDestDialog from './AddDestDialog.js'
import NMoTApiUrls from 'src/apiUrls/NMoTApiUrls.js'
import CodeMaintainanceApi from 'src/views/common/api/CodeMatainanceApi'
import EditDestDialog from './EditDestDialog.js'
import DisclaimerPromptDialog from 'src/views/nmot/shared/component/DisclaimerPromptDialog'
import CngBackdrop from 'src/views/vesselschedule/searchschedule/cngcomponent/CngBackDrop'

const {
  table: { CngCrudTable, useDefaultNotification, DateRangeFilter}
} = components

const { CodeMaintenanceType } = constants

const useStyles = makeStyles((theme) => ({
  div: {
    '& .MuiTable-root': {
      '& .MuiTableHead-root .MuiTableRow-head .MuiTableCell-head': {
        borderBottom: 'none',
        fontWeight: 'bold',
        '&:nth-child(1)': {
          width: '15% !important'
        },
        '&:nth-child(2)': {
          width: '20% !important'
        },
        '&:nth-child(3)': {
          width: '25% !important'
        },
        '&:nth-child(4)': {
          width: '5% !important'
        },
        '&:nth-child(n + 5):nth-last-child(n + 2)': {
          width: '15% !important'
        },
        '&:nth-last-child(1)': {
          width: '5% !important'
        }
      },
      '& .MuiTableRow-root[index]': {
        '&:nth-child(even)': {
          backgroundColor: '#fafbfc'
        },
        '& td[class*="MuiTableCell-root"]': {
          border: '1px solid #f1f4f9 !important',
          borderLeft: 'none !important',
          borderRight: 'none !important',
          '&:nth-child(1)': {
            borderLeft: '1px solid #f1f4f9 !important',
            borderRadius: '5px 0 0 5px !important',
            width: '15%  !important'
          },
          '&:nth-child(2)': {
            width: '20% !important'
          },
          '&:nth-child(3)': {
            width: '25% !important'
          },
          '&:nth-child(4)': {
            width: '5% !important'
          },
          '&:nth-child(n + 5):nth-last-child(n + 2)': {
            width: '15% !important'
          },
          '&:nth-last-child(1)': {
            width: '5% !important',
            borderRight: '1px solid #f1f4f9 !important',
            borderRadius: '0 5px 5px 0 !important'
          }
        }
      }
    }
  },
  trashIcon: {
    color: theme.palette.text.error,
    width: theme.spacing(2),
    height: theme.spacing(2)
  },
  editIcon: {
    color: theme.palette.primary.main,
    width: theme.spacing(2),
    height: theme.spacing(2)
  },
  addIcon: {
    color: theme.palette.primary.main,
    width: theme.spacing(3),
    height: theme.spacing(3)
  }
}))

function HinterlandDestTable(props) {
  const translatedTextsObject = NmotTranslationText()
  const { fetchRecords, securedSendRequest } = useServices()
  const classes = useStyles()
  const { showNotification } = props

  const notification = useDefaultNotification(showNotification)

  const tableRef = useRef()
  const cngTableRef = useRef()
  const darkRef = useRef('')
  const [loading, setLoading] = useState(true)
  const [countryList, setCountryList] = useState([])

  const tableRefStateCallback = useCallback(() => {
    return tableRef.current?.state
  }, [])

  function renderDateColumn(date) {
    if (!date) {
      return null
    }

    return <span>{moment(date).format('DD/MM/YYYY')}</span>
  }

  const columns = [
    {
      field: 'loCode',
      title: translatedTextsObject.loCode,
      show: true,
      render: (rowData) => {
        return <Box>{rowData.loCode}</Box>
      }
    },
    {
      field: 'place',
      title: translatedTextsObject.place,
      show: true,
      render: (rowData) => {
        return <Box>{rowData.place}</Box>
      }
    },
    {
      field: 'country',
      title: translatedTextsObject.country,
      show: true,
      render: (rowData) => {
        let country_desc = countryList.filter(
          (c) => c.code == rowData.countryCode
        )[0].descriptionEn
        return <Box>{country_desc}</Box>
      }
    },
    {
      field: 'active',
      title: translatedTextsObject.active,
      show: true,
      type: 'boolean',
      render: (rowData) => {
        return <Box>{rowData.active ? <Check /> : null}</Box>
      }
    },
    {
      field: 'createdDate',
      title: translatedTextsObject.createdDate,
      show: true,
      render: (rowData) => {
        return renderDateColumn(rowData.createdDate)
      },
      type: 'date',
      filterComponent: DateRangeFilter
    },
    {
      field: 'updatedDate',
      title: translatedTextsObject.updatedDate,
      show: true,
      render: (rowData) => {
        return renderDateColumn(rowData.updatedDate)
      },
      type: 'date',
      filterComponent: DateRangeFilter
    }
  ]

  useEffect(() => {
    CodeMaintainanceApi.fetchCodeMaintainance(
      fetchRecords,
      CodeMaintenanceType.COUNTRY,
      [],
      false,
      onLoadCountrySuccess
    )

    function onLoadCountrySuccess(data) {
      setCountryList(data)
      securedSendRequest.execute(
        'GET',
        `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/user-preference/by-current-user`,
        {},
        (data) => {
          darkRef.current = data.data.theme
        },
        (error) => {
          console.log(error)
        },
        onComplete
      )
    }

    function onComplete() {
      setLoading(false)
    }
  }, [])

  const [isAddDestDialogOpen, setAddDestDialogOpen] = useState(false)

  function closeDialog() {
    setAddDestDialogOpen(false)
  }

  const [isEditDestDialogOpen, setEditDestDialogOpen] = useState(false)

  function closeEditDialog() {
    setEditDestDialogOpen(false)
  }

  const [editRowData, setEditRowData] = useState({})

  const editRow = (data) => {
    setEditDestDialogOpen(true)
    setEditRowData(data)
  }

  return (
    <Paper>
      {!loading ? (
        <Box
          m='8px'
          className={darkRef.current.trim() === 'LIGHT' ? classes.div : ''}
        >
          <CngCrudTable
            {...props}
            fieldLevel=''
            tableRef={(ref) => {
              if (ref === null) {
                return
              }

              tableRef.current = ref
            }}
            columns={columns}
            del={{
              url: NMoTApiUrls.H_DEST_DELETE
            }}
            fetch={{
              url: NMoTApiUrls.H_DEST_GET
            }}
            notification={notification}
            options={{
              filtering: true,
              draggable: false
            }}
            localization={{
              header: {
                actions: 'Actions'
              }
            }}
            deleteButtonProps={{
              icon: ({ color, ...otherProps }) => (
                <Trash {...otherProps} className={classes.trashIcon} />
              ),
              tooltip: translatedTextsObject.remove
            }}
            refreshButtonProps={{
              hidden: true
            }}
            actions={[
              {
                icon: () => <Edit2 className={classes.editIcon} />,
                tooltip: translatedTextsObject.edit,
                onClick: (event, rowData) => editRow(rowData)
              },
              {
                icon: (props) => <Plus className={classes.addIcon} />,
                tooltip: translatedTextsObject.add,
                isFreeAction: true,
                onClick: (event, rowData) => setAddDestDialogOpen(true)
              }
            ]}
            fetchMode='FULL'
            customToolbar={(toolbar) => {
              const page = tableRefStateCallback()?.currentPage || 0
              const pageSize = tableRefStateCallback()?.pageSize || 0
              const totalResult = tableRefStateCallback()?.data?.length || 0

              return (
                <Box display='flex' alignItems='center'>
                  <Box pl='8px'>
                    <Typography variant='body2'>{`Showing ${Math.min(
                      page * pageSize + 1,
                      totalResult
                    )}-${Math.min(
                      (page + 1) * pageSize,
                      totalResult
                    )} of ${totalResult}`}</Typography>
                  </Box>
                  <Box flexDirection='row-reverse' flexGrow={1}>
                    {toolbar}
                  </Box>
                </Box>
              )
            }}
            postFetch={(data) => {
              return data.map((d) => ({ ...d, id: d.id }))
            }}
            cngTableRef={cngTableRef}
          />
        </Box>
      ) : (
        <CngBackdrop loading={loading} />
      )}
      <AddDestDialog
        isDialogOpen={isAddDestDialogOpen}
        closeDialog={closeDialog}
        showNotification={showNotification}
        cngTableRef={cngTableRef}
      />
      <EditDestDialog
        isDialogOpen={isEditDestDialogOpen}
        closeDialog={closeEditDialog}
        rowData={{ ...editRowData }}
        showNotification={showNotification}
        cngTableRef={cngTableRef}
      />
      <DisclaimerPromptDialog
        showNotification={showNotification}
      />
    </Paper>
  )
}

export default HinterlandDestTable
