import { Yup, components, useServices } from 'cng-web-lib'
import React, { useContext, useState } from 'react'

import CancelButton from 'src/components/button/CancelButton.js'
import NmotTranslationText from 'src/views/nmot/shared/NmotTranslationText'
import ValidationMessageTranslationText from 'src/views/nmot/shared/ValidationMessageTranslationText'
import * as ValidationRegex from 'src/views/nmot/shared/ValidationRegex'
import AddDestDialogContext from './AddDestDialogContext.js'
import SubmitButton from 'src/components/button/SubmitButton.js'
import NMoTApiUrls from 'src/apiUrls/NMoTApiUrls.js'
import { Typography, Box } from '@material-ui/core'
import CountrySelectAutoComplete from 'src/views/common/ui/CountrySelectAutoComplete.js'
import { v4 as uuid } from 'uuid'

const {
  form: {
    CngViewForm,
    field: { CngCheckboxField, CngTextField }
  },
  table: { useDefaultNotification }
} = components

function AddDestDialogForm(props) {
  const translatedTextsObject = NmotTranslationText()
  const { createRecord } = useServices()
  const dialogContext = useContext(AddDestDialogContext)
  const {
    closeDialog,
    showNotification,
    form: { isSubmitting, setSubmitting },
    cngTableRef
  } = dialogContext
  const { success: showSuccessNotification, error: showErrorNotification } =
    useDefaultNotification(showNotification)

  function onSubmit(data) {
    function onSuccess() {
      setSubmitting(false)
      closeDialog()
      if (cngTableRef.current && cngTableRef.current.performRefresh) {
        cngTableRef.current.performRefresh()
      }
      showSuccessNotification(translatedTextsObject.addHinterlandDestSuccess)
    }

    function onError(error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.errorMessages &&
        error.response.data.errorMessages.length > 0
      ) {
        showErrorNotification(error.response.data.errorMessages)
      } else {
        showErrorNotification(error.message)
      }
      console.log('hinterland-destination-add error', error.message)
      setSubmitting(false)
    }

    setSubmitting(true)

    createRecord.execute(
      NMoTApiUrls.H_DEST_PUT,
      {
        ...data,
        loCode: data.loCode.toUpperCase(),
        place: data.place.toUpperCase()
      },
      onSuccess,
      onError
    )
  }

  const validationMessageTranslation = ValidationMessageTranslationText()

  const validationSchema = Yup.object({
    loCode: Yup.string()
      .required(validationMessageTranslation.required)
      .max(5, validationMessageTranslation.wordLimitExceedMsg + '5')
      .min(2, validationMessageTranslation.wordLimitMinMsg + '2')
      .matches(
        ValidationRegex.spaceNotAllowedRegex,
        validationMessageTranslation.invalidStringWspace
      ),
    place: Yup.string()
      .required(validationMessageTranslation.required)
      .max(100, validationMessageTranslation.wordLimitExceedMsg + '100')
      .test('place', validationMessageTranslation.invalidEmojiMsg, (value) =>
        ValidationRegex.emojiRegx.test(value)
      ),
    countryCode: Yup.string()
      .required(validationMessageTranslation.required)
      .nullable()
  })

  return (
    <CngViewForm
      formikProps={{
        initialValues: {
          ...initialValues
        },
        validationSchema: validationSchema,
        onSubmit: onSubmit,
        validateOnChange: false
      }}
      renderBodySection={() => (
        <FormBody closeDialog={closeDialog} isSubmitting={isSubmitting} />
      )}
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
    />
  )
}

function FormBody({ closeDialog, isSubmitting }) {
  const translatedTextsObject = NmotTranslationText()
  const [countryKey, setCountryKey] = useState(uuid())

  return (
    <Box m={1.5}>
      <Box mb={2}>
        <Typography variant='body2' gutterBottom>
          {translatedTextsObject.hinterlandLocationDetails}
        </Typography>
      </Box>

      <Box my={1}>
        <CngTextField
          type='text'
          name='loCode'
          label={translatedTextsObject.loCode}
          inputProps={{ maxLength: 5 }}
          isRequired
        />
      </Box>
      <Box my={1}>
        <CngTextField
          type='text'
          name='place'
          label={translatedTextsObject.place}
          inputProps={{ maxLength: 100 }}
          isRequired
        />
      </Box>
      <Box my={1}>
        <CountrySelectAutoComplete
          name='countryCode'
          label={translatedTextsObject.country}
          key={countryKey}
          required
        />
      </Box>

      <Box my={1}>
        <CngCheckboxField
          disabled={isSubmitting}
          name='active'
          label={
            <Typography variant='body2' gutterBottom>
              {translatedTextsObject.active}
            </Typography>
          }
        />
      </Box>

      <Box display='flex' flexDirection={'row-reverse'} mt={2}>
        <SubmitButton isSubmitting={isSubmitting}>
          {translatedTextsObject.add}
        </SubmitButton>
        <Box mr={1}>
          <CancelButton onClick={closeDialog} disabled={isSubmitting} />
        </Box>
      </Box>
    </Box>
  )
}

const initialValues = {
  loCode: '',
  active: true,
  place: '',
  countryCode: ''
}

export default AddDestDialogForm
