import {
  Box,
  Paper,
  Grid,
  Button,
  Typography,
  makeStyles
} from '@material-ui/core'
import { Download, Upload } from 'react-feather'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  components,
  useServices,
  constants,
  DateTimeFormatter
} from 'cng-web-lib'
import moment from 'moment'
import NmotTranslationText from 'src/views/nmot/shared/NmotTranslationText'
import NMoTApiUrls from 'src/apiUrls/NMoTApiUrls.js'
import CngBackdrop from 'src/views/vesselschedule/searchschedule/cngcomponent/CngBackDrop'
import DownloadFileDialog from './DownloadFileDialog.js'
import DisclaimerPromptDialog from 'src/views/nmot/shared/component/DisclaimerPromptDialog'

const {
  table: { CngCrudTable, useDefaultNotification },
  dropzone: { CngFileUpload, HelperTextArea },
  CngGridItem
} = components

const useStyles = makeStyles((theme) => ({
  div: {
    '& .MuiTable-root': {
      '& .MuiTableHead-root .MuiTableRow-head .MuiTableCell-head': {
        backgroundColor: '#f2f7fc',
        fontWeight: 'bold',
        '&:nth-child(1)': {
          width: '15% !important'
        },
        '&:nth-child(2)': {
          width: '30% !important'
        },
        '&:nth-child(3)': {
          width: '30% !important'
        },
        '&:nth-child(4)': {
          width: '15% !important'
        },
        '&:nth-last-child(1)': {
          width: '5% !important'
        }
      },
      '& .MuiTableRow-root[index]': {
        '&:nth-child(even)': {
          backgroundColor: '#fafbfc'
        },
        '& td[class*="MuiTableCell-root"]': {
          border: '1px solid #f1f4f9 !important',
          borderLeft: 'none !important',
          borderRight: 'none !important',
          '&:nth-child(1)': {
            borderLeft: '1px solid #f1f4f9 !important',
            borderRadius: '5px 0 0 5px !important',
            width: '15%  !important'
          },
          '&:nth-child(2)': {
            width: '30% !important'
          },
          '&:nth-child(3)': {
            width: '30% !important'
          },
          '&:nth-child(4)': {
            width: '15% !important'
          },
          '&:nth-last-child(1)': {
            width: '5% !important',
            borderRight: '1px solid #f1f4f9 !important',
            borderRadius: '0 5px 5px 0 !important'
          }
        }
      }
    }
  },
  downloadIcon: {
    color: theme.palette.primary.main,
    width: theme.spacing(2),
    height: theme.spacing(2)
  }
}))

function UploadNmotTable(props) {
  const translatedTextsObject = NmotTranslationText()
  const { createRecord, securedSendRequest, fetchRecords } = useServices()
  const classes = useStyles()
  const { showNotification } = props

  const { success: showSuccessNotification, error: showErrorNotification } =
    useDefaultNotification(showNotification)
  const notification = useDefaultNotification(showNotification)

  const [pendingDocs, setPendingDocs] = useState([])
  const [fileData, setFileData] = useState('')
  const [nmotUploadedFiles, setNmotUploadedFiles] = useState([])
  const [pollingCount, setPollingCount] = useState(0)
  const [uploadingFiles, setUploadingFiles] = useState(false)
  const statusName = { 7103: 'Processing', 29: 'Completed', 20043: 'Failed' }

  const tableRef = useRef()
  const cngTableRef = useRef()
  const darkRef = useRef('')
  const [loading, setLoading] = useState(true)

  const tableRefStateCallback = useCallback(() => {
    return tableRef.current?.state
  }, [])

  function renderDateColumn(date) {
    if (!date) {
      return null
    }

    return <span>{moment(date).format('DD/MM/YYYY HH:mm:ss')}</span>
  }

  const columns = [
    {
      field: 'createdDate',
      title: translatedTextsObject.createdDate,
      show: true,
      defaultSort: 'desc',
      render: (rowData) => {
        return renderDateColumn(rowData.createdDate)
      }
    },
    {
      field: 'fileContentFileName',
      title: translatedTextsObject.fileContent,
      show: true,
      render: (rowData) => {
        return <Box>{rowData.fileContentFileName}</Box>
      }
    },
    {
      field: 'responseFileName',
      title: translatedTextsObject.response,
      show: true,
      render: (rowData) => {
        return <Box>{rowData.responseFileName}</Box>
      }
    },
    {
      field: 'status',
      title: translatedTextsObject.status,
      show: true,
      render: (rowData) => {
        let key = rowData.status
        return <Box>{statusName[key]}</Box>
      }
    }
  ]

  useEffect(() => {
    securedSendRequest.execute(
      'GET',
      `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/user-preference/by-current-user`,
      {},
      (data) => {
        darkRef.current = data.data.theme
      },
      (error) => {
        console.log(error)
      },
      onComplete
    )

    function onComplete() {
      fetchRecords.execute(
        NMoTApiUrls.NMOT_UPLOAD_GET,
        undefined,
        (data) => {
          //console.log('data from first load:' + JSON.stringify(data))
          let processingFiles = []
          data.content.forEach((file) => {
            if (file.status === 7103) {
              processingFiles.push(file)
            }
          })

          if (processingFiles.length > 0) {
            setNmotUploadedFiles(processingFiles)
            setPollingCount(pollingCount + 1)
          }
          setLoading(false)
        },
        (error) => {
          console.log(error)
          setLoading(false)
        }
      )
    }
  }, [])

  useEffect(() => {
    if (uploadingFiles) {
      resetUploadedFiles()
      setUploadingFiles(false)
    }
  }, [uploadingFiles])

  const [isDownloadLogDialogOpen, setDownloadLogDialogOpen] = useState(false)

  function closeDownloadDialog() {
    setDownloadLogDialogOpen(false)
  }

  const [downloadRowData, setDownloadRowData] = useState({})

  const downloadRow = (data) => {
    setDownloadLogDialogOpen(true)
    setDownloadRowData(data)
  }

  const moreActions = [
    {
      action: 'remove',
      name: 'Delete',
      icon: ['fal', 'trash']
    }
  ]

  function setSelectedFiles(file) {
    if (file.length > 0) {
      var reader = new FileReader()
      reader.readAsDataURL(file[0].file)
      reader.onloadend = function () {
        let base64data = reader.result
        // Gets rid of additional non-fileContent related string
        let dataStr = base64data.toString().split(';base64,')[1]
        setFileData(dataStr)
      }
    }
    setPendingDocs(file)
  }

  const resetUploadedFiles = () => {
    setFileData('')
    setPendingDocs([])
  }

  const uploadNMoT = () => {
    function onSuccess(response) {
      //console.log('on success data: ' + JSON.stringify(response))
      if (cngTableRef.current && cngTableRef.current.performRefresh) {
        cngTableRef.current.performRefresh()
      }

      let temp = [...nmotUploadedFiles]
      temp.push(response)
      setNmotUploadedFiles(temp)
      setPollingCount(pollingCount + 1)
      showSuccessNotification(translatedTextsObject.uploadFileSuccess)
    }

    function onError(error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.errorMessages &&
        error.response.data.errorMessages.length > 0
      ) {
        showErrorNotification(error.response.data.errorMessages)
      } else {
        showErrorNotification(error.message)
      }
      console.log('Upload File error', error.message)
    }

    if (pendingDocs.length > 0) {
      createRecord.execute(
        NMoTApiUrls.NMOT_UPLOAD_PUT,
        {
          offset: new Date().getTimezoneOffset(),
          timezone: null,
          fileContentDateCreated: DateTimeFormatter.toClientDate(new Date()),
          fileContentFileSize: pendingDocs[0].file.size,
          fileContentFileName: pendingDocs[0].file.name,
          fileContentData: fileData
        },
        onSuccess,
        onError
      )

      setUploadingFiles(true)
    } else {
      showErrorNotification('No file uploaded')
    }
  }

  useEffect(() => {
    if (pollingCount !== 0 && nmotUploadedFiles.length > 0) {
      setTimeout(() => {
        createRecord.execute(
          NMoTApiUrls.NMOT_UPLOAD_STATUS_GET,
          nmotUploadedFiles,
          (data) => {
            //console.log('data returned: ' + JSON.stringify(data.content))
            let statusArr = data.content

            let completedFilesId = []
            let pendingFilesId = []
            statusArr.forEach((file) => {
              if (file.status === 29 || file.status === 20043) {
                completedFilesId.push(file.id)
              } else if (file.status === 7103) {
                pendingFilesId.push(file.id)
              } else {
                console.log('Invalid file status: ' + file.status)
              }
            })

            if (pendingFilesId != null && pendingFilesId.length > 0) {
              setPollingCount(pollingCount + 1)
              if (completedFilesId != null && completedFilesId.length > 0) {
                let temp = []
                nmotUploadedFiles.forEach((file) => {
                  if (!completedFilesId.includes(file.id)) {
                    temp.push(file)
                  }
                })
                setNmotUploadedFiles(temp)
                if (cngTableRef.current && cngTableRef.current.performRefresh) {
                  cngTableRef.current.performRefresh()
                }
              }
            } else {
              console.log('No processing files')
              setPollingCount(0)
              setNmotUploadedFiles([])
              if (cngTableRef.current && cngTableRef.current.performRefresh) {
                cngTableRef.current.performRefresh()
              }
            }
          },
          (error) => {
            console.log(error)
            setPollingCount(pollingCount + 1)
          }
        )
      }, 1000)
    }
  }, [pollingCount])

  return (
    <Paper>
      {!loading ? (
        <Box
          m='8px'
          className={darkRef.current.trim() === 'LIGHT' ? classes.div : ''}
        >
          <CngCrudTable
            {...props}
            fieldLevel=''
            tableRef={(ref) => {
              if (ref === null) {
                return
              }

              tableRef.current = ref
            }}
            columns={columns}
            fetch={{
              url: NMoTApiUrls.NMOT_UPLOAD_GET
            }}
            pageSize={5}
            notification={notification}
            options={{
              filtering: false,
              draggable: false
            }}
            localization={{
              header: {
                actions: 'Actions'
              }
            }}
            deleteButtonProps={{
              hidden: true
            }}
            refreshButtonProps={{
              hidden: true
            }}
            actions={[
              {
                icon: () => <Download className={classes.downloadIcon} />,
                tooltip: translatedTextsObject.download,
                onClick: (event, rowData) => downloadRow(rowData)
              }
            ]}
            fetchMode='FULL'
            customToolbar={(toolbar) => {
              const page = tableRefStateCallback()?.currentPage || 0
              const pageSize = tableRefStateCallback()?.pageSize || 0
              const totalResult = tableRefStateCallback()?.data?.length || 0

              return (
                <Box display='flex' alignItems='center'>
                  <Box pl='8px'>
                    <Typography variant='body2'>{`Showing ${Math.min(
                      page * pageSize + 1,
                      totalResult
                    )}-${Math.min(
                      (page + 1) * pageSize,
                      totalResult
                    )} of ${totalResult}`}</Typography>
                  </Box>
                  <Box flexDirection='row-reverse' flexGrow={1}>
                    {toolbar}
                  </Box>
                </Box>
              )
            }}
            postFetch={(data) => {
              //console.log('PostFetch: ' + JSON.stringify(data))
              let sortedData = data.map((d) => ({ ...d, id: d.id }))
              sortedData.sort(function compareFn(firstEl, secondEl) {
                // Sorts by status first (Processing status at the top) followed by created date
                if (
                  secondEl.status == 7103 &&
                  (firstEl.status == 29 || firstEl.status == 20043)
                ) {
                  return 1
                }
                if (
                  firstEl.status == 7103 &&
                  (secondEl.status == 29 || secondEl.status == 20043)
                ) {
                  return -1
                }
                if (
                  (firstEl.status == 7103 &&
                    firstEl.status == secondEl.status) ||
                  ((firstEl.status == 20043 || firstEl.status == 29) &&
                    (secondEl.status == 20043 || secondEl.status == 29))
                ) {
                  if (firstEl.createdDate > secondEl.createdDate) {
                    return -1
                  }
                  if (firstEl.createdDate < secondEl.createdDate) {
                    return 1
                  }

                  return 0
                }
              })
              return sortedData
            }}
            cngTableRef={cngTableRef}
          />
        </Box>
      ) : (
        <CngBackdrop loading={loading} />
      )}

      <Box mt={2} mx={2}>
        <Grid container spacing={2}>
          <CngGridItem xs={12} sm={12}>
            <Typography variant='subtitle1' style={{ fontWeight: 'bold' }}>
              {translatedTextsObject.uploadForm}
            </Typography>
          </CngGridItem>
          <CngGridItem xs={12} sm={12}>
            <CngFileUpload
              accept={['.xlsx']}
              maxFiles={1}
              onFileSelect={(file) => setSelectedFiles(file)}
              files={pendingDocs}
              moreActions={moreActions}
              onDropRejected={(error) => showErrorNotification(error[0])}
              renderHelperText={() => {
                return (
                  <HelperTextArea
                    accept={['.xlsx']}
                    maxFiles={1}
                  />
                )
              }}
            />
          </CngGridItem>
        </Grid>

        <Grid container spacing={2} justify='center'>
          <Box pr={2} pb={2} pt={1}>
            <Button
              variant='contained'
              classes={{ root: 'ng-button-filled-secondary' }}
              style={{ width: '120px' }}
              startIcon={<Upload />}
              onClick={() => {
                uploadNMoT()
              }}
            >
              {translatedTextsObject.upload}
            </Button>
          </Box>
          <Box pr={2} pb={2} pt={1}>
            <Button
              variant='contained'
              classes={{ root: 'ng-button-filled-primary' }}
              style={{ width: '120px' }}
              onClick={() => {
                resetUploadedFiles()
              }}
            >
              {translatedTextsObject.reset}
            </Button>
          </Box>
          <Box pb={2} pt={1}>
            <a
              className='download'
              href={
                process.env.PUBLIC_URL +
                '/static/doc/Upload Record_Next Mode_template.xlsx'
              }
              download={'Upload Record_Next Mode_template.xlsx'}
            >
              <Button
                variant='contained'
                classes={{ root: 'ng-button-filled-secondary' }}
                style={{ width: '120px' }}
                startIcon={<Download />}
              >
                {translatedTextsObject.template}
              </Button>
            </a>
          </Box>
        </Grid>
      </Box>
      <DownloadFileDialog
        isDialogOpen={isDownloadLogDialogOpen}
        closeDialog={closeDownloadDialog}
        showNotification={showNotification}
        downloadRowData={downloadRowData}
        cngTableRef={cngTableRef}
      />
      <DisclaimerPromptDialog
        showNotification={showNotification}
      />
    </Paper>
  )
}

export default UploadNmotTable
