import { components, useServices } from 'cng-web-lib'
import React, { useContext, useState } from 'react'

import CancelButton from 'src/components/button/CancelButton.js'
import NmotTranslationText from 'src/views/nmot/shared/NmotTranslationText'
import SelectedRowsDialogContext from './SelectedRowsDialogContext.js'
import NMoTApiUrls from 'src/apiUrls/NMoTApiUrls.js'
import { Box, Grid, CircularProgress } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExclamationTriangle,
  faExclamationCircle
} from '@fortawesome/free-solid-svg-icons'

const {
  table: { useDefaultNotification },
  button: { CngPrimaryButton, CngSecondaryButton }
} = components

function SelectedRowsDialogForm(props) {
  const translatedTextsObject = NmotTranslationText()
  const { createRecord, deleteRecord } = useServices()
  const dialogContext = useContext(SelectedRowsDialogContext)
  const {
    closeDialog,
    showNotification,
    rowData,
    setSelectedRowData,
    form: { isDeleting, setDeleting, isArchiving, setArchiving },
    cngTableRef
  } = dialogContext
  const { success: showSuccessNotification, error: showErrorNotification } =
    useDefaultNotification(showNotification)

  const [noSelection, setNoSelection] = useState(
    rowData.length > 0 ? false : true
  )

  function onArchiveSelected() {
    function onSuccess() {
      setArchiving(false)
      closeDialog()
      if (cngTableRef.current && cngTableRef.current.performRefresh) {
        cngTableRef.current.performRefresh()
      }
      showSuccessNotification(translatedTextsObject.recordsArchiveSuccess)
      setSelectedRowData([])
    }

    function onError(error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.errorMessages &&
        error.response.data.errorMessages.length > 0
      ) {
        showErrorNotification(error.response.data.errorMessages)
      } else {
        showErrorNotification(error.message)
      }
      console.log('nmot-archive-multiple error', error.message)
      setArchiving(false)
    }

    setArchiving(true)

    createRecord.execute(
      NMoTApiUrls.NMOT_DASHBOARD_M_ARCHIVE,
      rowData,
      onSuccess,
      onError
    )
  }

  function onDeleteSelected() {
    function onSuccess(data) {
      console.log("delete return data: " + JSON.stringify(data))
      setDeleting(false)
      closeDialog()
      if (cngTableRef.current && cngTableRef.current.performRefresh) {
        cngTableRef.current.performRefresh()
      }
      showSuccessNotification(translatedTextsObject.recordsDeleteSuccess)
      setSelectedRowData([])
    }

    function onError(error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.errorMessages &&
        error.response.data.errorMessages.length > 0
      ) {
        showErrorNotification(error.response.data.errorMessages)
      } else {
        showErrorNotification(error.message)
      }
      console.log('nmot-delete-multiple error', error.message)
      setDeleting(false)
    }

    setDeleting(true)

    deleteRecord.execute(
      NMoTApiUrls.NMOT_DASHBOARD_M_DELETE,
      rowData,
      onSuccess,
      onError
    )
  }

  return (
    <Box m={1.5}>
      <Grid container spacing={2} justify='center' alignItems='center'>
        <Grid item xs={2} sm={2}>
          <Box py={1} pl={3}>
            <FontAwesomeIcon
              icon={noSelection ? faExclamationTriangle : faExclamationCircle}
              color={noSelection ? '#F4BE5E' : '#1769aa'}
              style={{ fontSize: 30 }}
            />
          </Box>
        </Grid>
        <Grid item xs={10} sm={10}>
          {noSelection ? (
            <Box>
              <div>Please select or filter record(s) to archive/delete.</div>
            </Box>
          ) : (
            <Box>
              <div>
                Do you want to archive/delete ({rowData.length}) records?
              </div>
            </Box>
          )}
        </Grid>
      </Grid>
      {noSelection ? (
        <Grid container spacing={2} justify='center'>
          <Box mr={1}>
            <CngSecondaryButton onClick={closeDialog}>OK</CngSecondaryButton>
          </Box>
        </Grid>
      ) : (
        <Grid container spacing={2} justify='center'>
          <Box my={1} mr={1}>
            <CngPrimaryButton
              disabled={isDeleting || isArchiving}
              onClick={() => {
                onArchiveSelected()
              }}
            >
              {translatedTextsObject.archiveSelected}
              {isArchiving && (
                <Box mx={0.5}>
                  <CircularProgress color='primary' size={10} />
                </Box>
              )}
            </CngPrimaryButton>
          </Box>
          <Box my={1} mr={1}>
            <CngPrimaryButton
              disabled={isDeleting || isArchiving}
              onClick={() => {
                onDeleteSelected()
              }}
            >
              {translatedTextsObject.deleteSelected}

              {isDeleting && (
                <Box mx={0.5}>
                  <CircularProgress color='primary' size={10} />
                </Box>
              )}
            </CngPrimaryButton>
          </Box>
          <Box my={1} mr={1}>
            <CancelButton
              disabled={isDeleting || isArchiving}
              onClick={closeDialog}
            />
          </Box>
        </Grid>
      )}
    </Box>
  )
}

export default SelectedRowsDialogForm
