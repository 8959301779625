import CommonUtils from 'src/views/common/utils/Utils'

function convertDate(data) {
  const dataArr = Object.entries(data)
  
      var val = data.pickupTime
      console.log("val, ", val)
      var dateToServer = CommonUtils.strictFormatDateAndConvertToGMT(
        val,
        CommonUtils.UI_NMOT_FORMAT_DATE_NUMBER,
        null
      )
      console.log("vdateToServeral, ", dateToServer)
      data.pickupTime = dateToServer
   

    return data
 
}

const Utils = Object.freeze({
 
  convertDate
})
export default Utils
