import NmotKeys from 'src/constants/locale/key/Nmot'
import Namespace from 'src/constants/locale/Namespace'
import { useTranslation } from 'cng-web-lib'

const NmotTranslationText = () => {
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.NMOT
  ])

  //---Common
  let add = translate(
    Namespace.NMOT,
    NmotKeys.ADD
  )

  let edit = translate(
    Namespace.NMOT,
    NmotKeys.EDIT
  )

  let view = translate(
    Namespace.NMOT,
    NmotKeys.VIEW
  )

  let back = translate(
    Namespace.NMOT,
    NmotKeys.BACK
  )

  let refresh = translate(
    Namespace.NMOT,
    NmotKeys.REFRESH
  )

  let remove = translate(
    Namespace.NMOT,
    NmotKeys.REMOVE
  )

  let apply = translate(
    Namespace.NMOT,
    NmotKeys.APPLY
  )

  let download = translate(
    Namespace.NMOT,
    NmotKeys.DOWNLOAD
  )

  let upload = translate(
    Namespace.NMOT,
    NmotKeys.UPLOAD
  )

  let reset = translate(
    Namespace.NMOT,
    NmotKeys.RESET
  )

  let confirm = translate(
    Namespace.NMOT,
    NmotKeys.CONFIRM
  )

  let warning = translate(
    Namespace.NMOT,
    NmotKeys.WARNING
  )

  let accept = translate(
    Namespace.NMOT,
    NmotKeys.ACCEPT
  )

  let disclaimerTitle= translate(
    Namespace.NMOT,
    NmotKeys.DISCLAIMER_TITLE
  )

  //---Hinterland Dest Master List
  let hinterlandDestML = translate(
    Namespace.NMOT,
    NmotKeys.HINTERLAND_DEST_ML
  )

  let loCode = translate(
    Namespace.NMOT,
    NmotKeys.LOCODE
  )

  let place = translate(
    Namespace.NMOT,
    NmotKeys.PLACE
  )

  let country = translate(
    Namespace.NMOT,
    NmotKeys.COUNTRY
  )

  let active = translate(
    Namespace.NMOT,
    NmotKeys.ACTIVE
  )

  let createdDate = translate(
    Namespace.NMOT,
    NmotKeys.CREATED_DATE
  )

  let updatedDate = translate(
    Namespace.NMOT,
    NmotKeys.UPDATED_DATE
  )

  let addDestinationTitle = translate(
    Namespace.NMOT,
    NmotKeys.ADD_DESTINATION_TITLE
  )

  let hinterlandLocationDetails = translate(
    Namespace.NMOT,
    NmotKeys.HINTERLAND_LOCATION_DETAILS
  )

  let addHinterlandDestSuccess = translate(
    Namespace.NMOT,
    NmotKeys.ADD_HINTERLAND_DEST_SUCCESS
  )

  let editDestinationTitle = translate(
    Namespace.NMOT,
    NmotKeys.EDIT_DESTINATION_TITLE
  )

  let editHinterlandDestSuccess = translate(
    Namespace.NMOT,
    NmotKeys.EDIT_HINTERLAND_DEST_SUCCESS
  )

  //---NMoT Admin
  let nmotAdmin = translate(
    Namespace.NMOT,
    NmotKeys.NMOT_ADMIN
  )

  let addNmotUserTitle = translate(
    Namespace.NMOT,
    NmotKeys.ADD_NMOT_USER_TITLE
  )

  let nmotUserDetails = translate(
    Namespace.NMOT,
    NmotKeys.NMOT_USER_DETAILS
  )

  let addNmotUserSuccess = translate(
    Namespace.NMOT,
    NmotKeys.ADD_NMOT_USER_SUCCESS
  )

  let editNmotUserTitle = translate(
    Namespace.NMOT,
    NmotKeys.EDIT_NMOT_USER_TITLE
  )

  let editNmotUserSuccess = translate(
    Namespace.NMOT,
    NmotKeys.EDIT_NMOT_USER_SUCCESS
  )

  let loginId = translate(
    Namespace.NMOT,
    NmotKeys.LOGIN_ID
  )

  let thirdParty = translate(
    Namespace.NMOT,
    NmotKeys.THIRD_PARTY
  )

  //---NMoT Api Logs
  let nmotLogs = translate(
    Namespace.NMOT,
    NmotKeys.NMOT_LOGS
  )

  let action = translate(
    Namespace.NMOT,
    NmotKeys.ACTION
  )

  let uploadedFile = translate(
    Namespace.NMOT,
    NmotKeys.UPLOADED_FILE
  )

  let response = translate(
    Namespace.NMOT,
    NmotKeys.RESPONSE
  )

  let selectDownloadFile = translate(
    Namespace.NMOT,
    NmotKeys.SELECT_DOWNLOAD_FILE
  )

  let downloadUploadFile = translate(
    Namespace.NMOT,
    NmotKeys.DOWNLOAD_UPLOAD_FILE
  )

  let downloadResponseFile = translate(
    Namespace.NMOT,
    NmotKeys.DOWNLOAD_RESPONSE_FILE
  )

  let downloadApiLogFile = translate(
    Namespace.NMOT,
    NmotKeys.DOWNLOAD_API_LOG_FILE
  )

  let downloadFileSucess = translate(
    Namespace.NMOT,
    NmotKeys.DOWNLOAD_FILE_SUCCESS
  )

  //---NMoT Add
  let nmotAdd= translate(
    Namespace.NMOT,
    NmotKeys.NMOT_ADD
  )

  let addNmotSuccess= translate(
    Namespace.NMOT,
    NmotKeys.ADD_NMOT_SUCCESS
  )

  let updateNmotSuccess= translate(
    Namespace.NMOT,
    NmotKeys.UPDATE_NMOT_SUCCESS
  )

  let nmotAddTitle= translate(
    Namespace.NMOT,
    NmotKeys.NMOT_ADD_TILE
  )

  let contNo = translate(
    Namespace.NMOT,
    NmotKeys.CONT_NO
  )

  let nmot = translate(
    Namespace.NMOT,
    NmotKeys.NMOT
  )

  let hinterlandDest = translate(
    Namespace.NMOT,
    NmotKeys.HINTERLAND_DEST
  )

  let nextCarrier = translate(
    Namespace.NMOT,
    NmotKeys.NEXT_CARRIER
  )

  let blNo = translate(
    Namespace.NMOT,
    NmotKeys.BLNO
  )

  let goodDesc = translate(
    Namespace.NMOT,
    NmotKeys.GOOD_DESC
  )

  let pickupTime = translate(
    Namespace.NMOT,
    NmotKeys.PICKUP_TIME
  )

  //---NMoT Upload
  let nmotUpload = translate(
    Namespace.NMOT,
    NmotKeys.NMOT_UPLOAD
  )

  let status = translate(
    Namespace.NMOT,
    NmotKeys.STATUS
  )

  let fileContent = translate(
    Namespace.NMOT,
    NmotKeys.FILE_CONTENT
  )

  let uploadForm = translate(
    Namespace.NMOT,
    NmotKeys.UPLOAD_FORM
  )

  let template = translate(
    Namespace.NMOT,
    NmotKeys.TEMPLATE
  )

  let uploadFileSuccess = translate(
    Namespace.NMOT,
    NmotKeys.UPLOAD_FILE_SUCCESS
  )

  //---NMoT Dashboard

  let nmotdashboard= translate(
    Namespace.NMOT,
    NmotKeys.NMOT_DASHBOARD
  )

  let slNo = translate(
    Namespace.NMOT,
    NmotKeys.SL_NO
  )

  let eToD = translate(
    Namespace.NMOT,
    NmotKeys.E_TO_D
  )

  let eToDdesc = translate(
    Namespace.NMOT,
    NmotKeys.E_TO_D_DESC
  )

  let gateOut = translate(
    Namespace.NMOT,
    NmotKeys.GATE_OUT
  )

  let overall = translate(
    Namespace.NMOT,
    NmotKeys.OVERALL
  )

  let onYardTerminal = translate(
    Namespace.NMOT,
    NmotKeys.ON_YARD_TERMINAL
  )

  let commercialRelease = translate(
    Namespace.NMOT,
    NmotKeys.COMM_RELEASE
  )

  let customsRelease = translate(
    Namespace.NMOT,
    NmotKeys.CUSTOMS_RELEASE
  )

  let notBot = translate(
    Namespace.NMOT,
    NmotKeys.NOT_BOT
  )

  let uploadedCreatedDate = translate(
    Namespace.NMOT,
    NmotKeys.UPLOADED_CREATED_DATE
  )

  let archiveDeleteSelected = translate(
    Namespace.NMOT,
    NmotKeys.ARCHIVE_DELETE_SELECTED
  )

  let archiveDeleteDownloadAll = translate(
    Namespace.NMOT,
    NmotKeys.ARCHIVE_DELETE_DOWNLOAD_ALL
  )

  let archiveAll = translate(
    Namespace.NMOT,
    NmotKeys.ARCHIVE_ALL
  )

  let deleteAll = translate(
    Namespace.NMOT,
    NmotKeys.DELETE_ALL
  )

  let downloadAll = translate(
    Namespace.NMOT,
    NmotKeys.DOWNLOAD_ALL
  )

  let archiveSelected = translate(
    Namespace.NMOT,
    NmotKeys.ARCHIVE_SELECTED
  )

  let deleteSelected = translate(
    Namespace.NMOT,
    NmotKeys.DELETE_SELECTED
  )

  let recordsArchiveSuccess = translate(
    Namespace.NMOT,
    NmotKeys.RECORDS_ARCHIVE_SUCCESS
  )

  let recordsDeleteSuccess = translate(
    Namespace.NMOT,
    NmotKeys.RECORDS_DELETE_SUCCESS
  )

  let recordsDownloadSuccess = translate(
    Namespace.NMOT,
    NmotKeys.RECORDS_DOWNLOAD_SUCCESS
  )

  let updating= translate(
    Namespace.NMOT,
    NmotKeys.UPDATING
  )

  let justRefreshed= translate(
    Namespace.NMOT,
    NmotKeys.JUST_REFRESHED
  )

  let note= translate(
    Namespace.NMOT,
    NmotKeys.NOTE
  )

  let errorRefresh= translate(
    Namespace.NMOT,
    NmotKeys.ERROR_REFRESH
  )

  //---NMoT Edit/View

  let nmotEdit= translate(
    Namespace.NMOT,
    NmotKeys.NMOT_EDIT
  )

  let nmotEditTitle= translate(
    Namespace.NMOT,
    NmotKeys.NMOT_EDIT_TILE
  )

  let nmotView= translate(
    Namespace.NMOT,
    NmotKeys.NMOT_VIEW
  )

  let nmotViewTitle= translate(
    Namespace.NMOT,
    NmotKeys.NMOT_VIEW_TILE
  )

  return {
    hinterlandDestML,
    loCode,
    place,
    country,
    active,
    upload,
    reset,
    createdDate,
    updatedDate,
    add,
    edit,
    view,
    back,
    refresh,
    confirm,
    warning,
    accept,
    disclaimerTitle,
    addDestinationTitle,
    hinterlandLocationDetails,
    remove,
    addHinterlandDestSuccess,
    editDestinationTitle,
    editHinterlandDestSuccess,
    apply,
    nmotAdmin,
    nmotLogs,
    addNmotUserTitle,
    nmotUserDetails,
    addNmotUserSuccess,
    editNmotUserTitle,
    editNmotUserSuccess,
    loginId,
    thirdParty,
    action,
    download,
    uploadedFile,
    response,
    selectDownloadFile,
    downloadUploadFile,
    downloadResponseFile,
    downloadApiLogFile,
    downloadFileSucess,
    nmotAdd,
    addNmotSuccess,
    updateNmotSuccess,
    nmotAddTitle,
    nmotUpload,
    contNo,
    nmot,
    hinterlandDest,
    nextCarrier,
    blNo,
    goodDesc,
    pickupTime,
    status,
    fileContent,
    uploadForm,
    template,
    uploadFileSuccess,

    nmotdashboard,
    slNo,
    eToD,
    eToDdesc,
    gateOut,
    overall,
    onYardTerminal,
    commercialRelease,
    customsRelease,
    notBot,
    uploadedCreatedDate,
    archiveDeleteSelected,
    archiveDeleteDownloadAll,
    archiveAll,
    deleteAll,
    downloadAll,
    nmotEdit,
    nmotView,
    nmotViewTitle,
    archiveSelected,
    deleteSelected,
    recordsArchiveSuccess,
    recordsDeleteSuccess,
    recordsDownloadSuccess,
    nmotEditTitle,
    updating,
    justRefreshed,
    note,
    errorRefresh
  }
}

export default NmotTranslationText