import { BasePage as CngBasePage } from 'cng-web-lib'
import HinterlandDestTable from './HinterlandDestTable.js'
import React from 'react'
import TranslationText from 'src/views/nmot/shared/NmotTranslationText'
import useBreadcrumbNameMap from 'src/navigations/breadcrumbs/Breadcrumb_Nmot'

function HinterlandDestTablePage(props) {
  const translatedTextsObject = TranslationText()
  const breadcrumbNameMap = useBreadcrumbNameMap()

  return (
    <CngBasePage
      moduleTitle={translatedTextsObject.hinterlandDestML}
      renderPage={(showSnackbar) => (
        <HinterlandDestTable
          showNotification={showSnackbar}
          {...props}
        />
      )}
      extraCngBreadcrumbNameMap={breadcrumbNameMap}
    />
  )
}

export { HinterlandDestTablePage }
