import React, { useEffect, useState } from 'react'
import NmotTranslationText from 'src/views/nmot/shared/NmotTranslationText'
import NMoTApiUrls from 'src/apiUrls/NMoTApiUrls.js'
import Cookies from 'js-cookie'
import { Paper, Box, Grid, Button } from '@material-ui/core'
import { components, useServices } from 'cng-web-lib'
import CircularProgress from '@material-ui/core/CircularProgress'

const {
  CngDialog,
  table: { useDefaultNotification },
  button: { CngPrimaryButton }
} = components

function DisclaimerPromptDialog({ showNotification }) {
  const { success: showSuccessNotification, error: showErrorNotification } =
    useDefaultNotification(showNotification)

  const translatedTextsObject = NmotTranslationText()
  const { fetchRecords, securedSendRequest } = useServices()
  const [isDisclaimerPromptDialogOpen, setDisclaimerPromptDialogOpen] =
    useState(false)
  const [nmotCookieKey, setNmotCookieKey] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const nmotCookies = 'NMOT-'
  const acceptedStatus = 'Accepted'

  useEffect(() => {
    console.log('Fetching disclaimer cookies')
    fetchRecords.execute(
      `${process.env.REACT_APP_USER_ORIGIN_URL}/tpr-auth/user-detail/by-login/get`,
      undefined,
      (data) => {
        console.log('fetchUserRoleFromAPI data: ', data)
        let key = nmotCookies + data[0].id
        setNmotCookieKey(key)
        let nmotCookie = Cookies.get(key)
        if (nmotCookie == null || nmotCookie != acceptedStatus) {
          setDisclaimerPromptDialogOpen(true)
        }
      },
      (error) => {
        console.log(error)
      }
    )
  }, [])

  function postAcceptCookie() {
    console.log('Post disclaimer cookies')
    setIsSubmitting(true)
    securedSendRequest.execute(
      'POST',
      NMoTApiUrls.NMOT_COOKIE_EXPIRED_CONFIG,
      {},
      onSuccess,
      onError
    )

    function onSuccess(data) {
      setCookie(nmotCookieKey, acceptedStatus, data.data)
      cookieAudit()
    }

    function onError(error) {
      console.log('NMoT cookie post error', error.message)
      if (
        error.response &&
        error.response.data &&
        error.response.data.errorMessages &&
        error.response.data.errorMessages.length > 0
      ) {
        showErrorNotification(error.response.data.errorMessages)
      } else {
        showErrorNotification(error.message)
      }
      setIsSubmitting(false)
    }
  }

  function cookieAudit() {
    console.log('Record set cookie audit')
    securedSendRequest.execute(
      'POST',
      NMoTApiUrls.NMOT_COOKIE_AUDIT,
      {},
      (data) => {
        setIsSubmitting(false)
        setDisclaimerPromptDialogOpen(false)
      },
      (error) => {
        console.log('NMoT cookie audit error', error.message)
        setIsSubmitting(false)
        setDisclaimerPromptDialogOpen(false)
      }
    )
  }

  function setCookie(name, value, days) {
    var expires = ''
    if (days) {
      var date = new Date()
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
      expires = '; expires=' + date.toUTCString()
    }
    // document.cookie =
    //   name + '=' + (value || '') + expires + '; Secure; path=/cal-nmot'
    //for path, i cannot add as {path:"/cal-mnot"}, hence will leave it for now. 
    Cookies.set(name, value||'', {expires: days, secure:true})
    console.log('document cookie', document.cookie)
  }

  return (
    <CngDialog
      customDialogContent={
        <Paper>
          <Box m={1.5}>
            <Box mt={2}>
              <embed
                type='text/html'
                src={`${process.env.PUBLIC_URL}/static/doc/nmot/nmotDisclaimer-show.html`}
                width='100%'
                height='600em'
              />
            </Box>
            <Box mt={2}>
              <Grid container className='sticky-footer' justify='center'>
                <Box>
                  <CngPrimaryButton
                    style={{ width: '120px' }}
                    disabled={isSubmitting}
                    onClick={postAcceptCookie}
                  >
                    {translatedTextsObject.accept}
                    {isSubmitting && (
                      <Box mx={0.5}>
                        <CircularProgress color='primary' size={10} />
                      </Box>
                    )}
                  </CngPrimaryButton>
                  {/* <Button
                    variant='contained'
                    classes={{ root: 'ng-button-filled-primary' }}
                    style={{ width: '120px' }}
                    onClick={postAcceptCookie}
                  >
                    {translatedTextsObject.accept}
                  </Button> */}
                </Box>
              </Grid>
            </Box>
          </Box>
        </Paper>
      }
      dialogTitle={<>{translatedTextsObject.disclaimerTitle}</>}
      open={isDisclaimerPromptDialogOpen}
      fullWidth
      maxWidth='md'
    />
  )
}

export default DisclaimerPromptDialog
